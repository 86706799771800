import React from 'react'
import logo from '../assets/black-level-logo.png'
const Navbar = () => {
  return (
    <a style={{height:95,backgroundColor:'white',borderBottomLeftRadius:20,borderBottomRightRadius:20,display:'flex',borderWidth:0}} href='https://www.levelso.com' target='_blank'>
        <img src={logo} alt="level solutions logo" style={{height:80,width:80,marginLeft:50,marginTop:10}} />
    </a>
  )
}

export default Navbar