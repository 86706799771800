import './App.css';
import MainPage from './pages/MainPage';
import MainPageMobile from './pages/MainPageMobile';
import React,{useState,useEffect} from 'react';

function App() {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const isMobile = windowSize.width < 768;
  console.log(isMobile)

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);


    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); 


  return (
    <div>
      {
        isMobile ?
        <MainPageMobile />
        :
        <MainPage />
      }
    </div>
  );
}

export default App;
