import React from 'react'
import Navbar from '../components/mobile/Navbar'
import Footer from '../components/mobile/Footer'
import MiddleSection from '../components/mobile/MiddleSection'


const MainPageMobile = () => {
  return (
    <div>
        <Navbar />
        <MiddleSection />
        <Footer />
    </div>
  )
}

export default MainPageMobile