import React from 'react';

const Footer = () => {
  return (
    <div style={{ height: '43px', backgroundColor: 'black', justifyContent: 'center', alignItems: 'center', display: 'flex', borderTopLeftRadius: '20px', borderTopRightRadius: '20px', position: 'fixed', bottom: '0', left: '0', width: '100%', zIndex: '999' }}>
      <p style={{ color: 'white', fontSize: '16px' }}>
        © 2024 Level Solutions. All rights reserved.
      </p>
    </div>
  );
};

export default Footer;
