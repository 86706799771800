import React from 'react'
import AppDesc from './AppDesc'
import AppView from './AppView'

const MiddleSection = () => {
  return (
    <div>
        <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
          <div style={{display:'flex',flexDirection:'column'}}>
        <AppDesc/>
        <AppView/>
          </div>
        </div>
    </div>
  )
}

export default MiddleSection