import React from 'react'
import AppDownloadButtons from './AppDownloadButtons'
const AppDesc = () => {
  return (
    <div>
        <h1 style={{fontSize:60,color:'#225125',fontFamily:'montserrat',textAlign:'center'}}>DaVinci Coffee</h1>
        <h1 style={{textAlign:'center',fontSize:20}}>Kafe Deneyiminizi Yeniden Keşfedin</h1>
        <h5 style={{fontSize:17,textAlign:'center'}}>Uygulama marketlerinden indirebilirsiniz</h5>
        <AppDownloadButtons />
    </div>
  )
}

export default AppDesc