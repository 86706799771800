import React from 'react'
import AppDesc from './AppDesc'
import AppDownloadButtons from './AppDownloadButtons'
import AppView from './AppView'

const MiddleSection = () => {
  return (
    <div>
        <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
        <AppDesc/>
        <AppView/>
        </div>
    </div>
  )
}

export default MiddleSection