import React from 'react'
import firstScreen from '../assets/first-screen.png'

const AppView = () => {
  return (
    <div>
        <img src={firstScreen} alt="first screen" style={{width:'100%'}} />
    </div>
  )
}

export default AppView