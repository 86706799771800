import React, { useState } from 'react';
import appleLogo from '../assets/apple-logo.png';
import playLogo from '../assets/play-store-logo.png';
import huaweiLogo from '../assets/huawei-logo.png';
import appleLogoBlack from '../assets/apple-logo-black.png'

const AppDownloadButtons = () => {
    const [isHoverApple, setIsHoverApple] = useState(false);
    const [isHoverPlay, setIsHoverPlay] = useState(false);
    const [isHoverHuawei, setIsHoverHuawei] = useState(false);

    return (
        <div style={{display: 'flex'}}>
            <a
                href='https://apps.apple.com/tr/app/davinci-coffee/id6478342035'
                target='_blank'
                onMouseEnter={() => setIsHoverApple(true)}
                onMouseLeave={() => setIsHoverApple(false)}
                style={{borderWidth: 0, backgroundColor: isHoverApple ? 'white' : 'black', height: 50, width: 175, borderRadius: 10, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',borderWidth:isHoverApple ? 2:0,borderColor:'black',cursor:'grab',textDecoration:'none'}}
            >
                <img src={!isHoverApple ? appleLogo : appleLogoBlack} alt="apple logo" style={{width: 30, height: 30}} />
                <p style={{color: !isHoverApple ? 'white' : 'black', fontSize: 20, marginRight: 10,marginLeft:10}}>App Store</p>
            </a>
            <a 
                href='https://play.google.com/store/apps/details?id=com.levelsolutions.davincicoffeeshop'
                target='_blank'
                onMouseEnter={() => setIsHoverPlay(true)}
                onMouseLeave={() => setIsHoverPlay(false)}
                style={{borderWidth: 0, backgroundColor: isHoverPlay ? 'white' : 'black', height: 50, width: 175, borderRadius: 10, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 20,borderWidth:isHoverPlay ? 2:0,borderColor:'black',cursor:'grab',textDecoration:'none'}}
            >
                <img src={playLogo} alt="play store logo" style={{width: 30, height: 30}} />
                <p style={{color: !isHoverPlay ? 'white' : 'black', fontSize: 20, marginRight: 10,marginLeft:10}}>Play Store</p>
            </a>
            <a 
                onMouseEnter={() => setIsHoverHuawei(true)}
                onMouseLeave={() => setIsHoverHuawei(false)}
                style={{borderWidth: 0, backgroundColor: isHoverHuawei ? 'white' : 'black', height: 50, width: 175, borderRadius: 10, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 20,borderWidth:isHoverHuawei ? 2:0,borderColor:'black',cursor:'grab'}}
            >
                <img src={huaweiLogo} alt="huawei logo" style={{width: 30, height: 30}} />
                <p style={{color: !isHoverHuawei ? 'white' : 'black', fontSize: 20, marginRight: 10,marginLeft:10}}>AppGallery</p>
            </a>
        </div>
    );
}

export default AppDownloadButtons;
