import React from 'react'
import AppDownloadButtons from './AppDownloadButtons'
const AppDesc = () => {
  return (
    <div>
        <h1 style={{fontSize:100,color:'#225125',fontFamily:'montserrat'}}>DaVinci Coffee</h1>
        <h1>Kafe Deneyiminizi Yeniden Keşfedin</h1>
        <h5 style={{fontSize:17}}>Uygulama marketlerinden indirebilirsiniz</h5>
        <AppDownloadButtons />
    </div>
  )
}

export default AppDesc