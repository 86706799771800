import React from 'react'
import firstScreen from '../../assets/first-screen.png'

const AppView = () => {
  return (
    <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
        <img src={firstScreen} alt="first screen" style={{width:'50%'}} />
    </div>
  )
}

export default AppView