import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import MiddleSection from '../components/MiddleSection'


const MainPage = () => {
  return (
    <div>
        <Navbar />
        <MiddleSection />
        <Footer />
    </div>
  )
}

export default MainPage